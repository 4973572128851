<template>
  <div>
    <z-header></z-header>
    <div class="min_width banner_image_box">
      <img src="../assets/img_case.png" alt="" />
    </div>
    <div class="page_center case_box">
      <div class="case_filter_box">
        <div class="case_filter">
          <div class="filter_list">
            <div class="name">开发类型 :</div>
            <div class="select_list">
              <span
                v-for="(item, index) in devTypeList"
                :key="index"
                :class="{ active: item.objId == devTypeId }"
                @click="onFilter(1000, item)"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div class="filter_list">
            <div class="name">开发平台 :</div>
            <div class="select_list">
              <span
                v-for="(item, index) in platformList"
                :key="index"
                :class="{ active: item.objId == platformId }"
                @click="onFilter(2000, item)"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div class="filter_list">
            <div class="name">应用行业 :</div>
            <div class="select_list">
              <span
                v-for="(item, index) in industryList"
                :key="index"
                :class="{ active: item.objId == industryId }"
                @click="onFilter(3000, item)"
                >{{ item.name }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="case_list_box">
        <router-link
          tag="div"
          :to="'/caseDetails/' + item.objId"
          class="case_item"
          v-for="(item, index) of caseList"
          :key="index"
        >
          <div class="image">
            <img :src="item.headImg" :alt="item.name" />
          </div>
        </router-link>
      </div>

      <z-page
        class="zpage_box"
        :current-page="pageNo"
        :total="pages"
        @change="getCaseList"
      ></z-page>
    </div>
    <z-footer></z-footer>
  </div>
</template>

<script>
import zPage from "@/components/common/page.vue";
export default {
  components: {
    zPage,
  },
  data() {
    return {
      pageNo: 1,
      total: 1,
      pages: 1,

      devTypeId: "",
      devTypeList: [
        {
          objId: "",
          name: "全部",
        },
      ],
      platformId: "",
      platformList: [
        {
          objId: "",
          name: "全部",
        },
      ],
      industryId: "",
      industryList: [
        {
          objId: "",
          name: "全部",
        },
      ],

      caseList: [],
    };
  },
  created() {
    this.getClassifyData();
    this.getCaseList();
  },
  methods: {
    // 获取项目类型
    getClassifyData() {
      this.$http.get("api/base_type/classify").then((res) => {
        let classifyData = res.data;
        this.devTypeList = this.devTypeList.concat(classifyData.devType);
        this.platformList = this.platformList.concat(classifyData.platform);
        this.industryList = this.industryList.concat(classifyData.industry);
      });
    },
    getCaseList(size) {
      if (size) {
        this.pageNo = size;
      }
      // eslint-disable-next-line prefer-const
      let httpData = {
        pageNo: this.pageNo,
        pageSize: 9,
      };
      if (this.devTypeId) {
        httpData.devType = this.devTypeId;
      }
      if (this.platformId) {
        httpData.platform = this.platformId;
      }
      if (this.industryId) {
        httpData.industry = this.industryId;
      }
      this.$http.post("api/case/case_main", httpData).then((res) => {
        this.total = res.data.count;
        this.caseList = res.data.data;
        this.pages = res.data.pages;
      });
    },
    // 分类切换
    onFilter(type, item) {
      if (type === 1000) {
        this.devTypeId = item.objId;
      } else if (type === 2000) {
        this.platformId = item.objId;
      } else if (type === 3000) {
        this.industryId = item.objId;
      }
      this.pageNo = 1;
      this.getCaseList();
    },
  },
};
</script>

<style lang="scss" scoped>
.banner_image_box {
  height: 580px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.case_box {
  padding: 60px 0 40px;

  .case_filter_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    border: 1px dashed #222222;
    background-color: #ffffff;

    .case_filter {
      width: 1014px;
      .filter_list {
        display: flex;
        margin-bottom: 20px;
        .name {
          width: 113px;
          flex-shrink: 0;
          line-height: 21px;
          font-size: 16px;
          color: #333333;
          font-weight: bold;
        }
        .select_list {
          display: flex;
          flex-wrap: wrap;
          span {
            height: 32px;
            font-size: 14px;
            color: #333333;
            line-height: 32px;
            margin-right: 30px;
            padding: 0 12px;
            cursor: pointer;
            &.active {
              background-color: #222222;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .case_list_box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    .case_item {
      width: 396px;
      height: 264px;
      margin: 0 40px 40px 0;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
      transition: transform .4s;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0px 1em 2em 0px rgba(48, 55, 66, 0.15);
        transform: translate(0, -10px);
      }
    }

    .image {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>